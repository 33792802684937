<script lang="ts" setup>
const props = defineProps<{
  src: string;
  maxWidth?: string;
  maxHeight?: string;
}>();

const styles = computed(() => {
  return `max-width: ${props.maxWidth ?? "100%"}; max-height: ${
    props.maxHeight ?? "100%"
  };`;
});
const img = useImage();
</script>

<template>
  <!--  <v-img :src="imgUrl" />-->
  <!--  <v-responsive>-->
  <nuxt-img
    :src="props.src ?? ''"
    preload
    fit="inside"
    :style="styles"
    format="webp"
  />
  <!--  </v-responsive>-->
</template>

<style scoped></style>
